<div class="why-choose-us bg-blue bg-choose-us-one bg_image rts-section-gap shape-move rts-section-gapBottom">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-us-area-image pb--50">
                    <img class="one" src="assets/images/why-choose/02.jpg" alt="why-choose">
                    <div class="border-img">
                        <img class="two ml--20" src="assets/images/why-choose/03.jpg" alt="why-choose">
                    </div>
                    <div class="circle-animation">
                        <a class="uni-circle-text uk-background-white dark:uk-background-gray-80 uk-box-shadow-large uk-visible@m"
                            href="#view_in_opensea">
                            <svg class="uni-circle-text-path uk-text-secondary uni-animation-spin"
                                viewBox="0 0 100 100" width="140" height="140">
                                <defs>
                                    <path id="circle" d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0">
                                    </path>
                                </defs>
                                <text font-size="11.2">
                                    <textPath xlink:href="#circle">.teaching • since 1999 •</textPath>
                                </text>
                                
                            </svg>
                            <i class="fa-regular fa-arrow-up-right"></i>
                        </a>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-6 pl--65 pl_sm--15">
                <div class="title-area-left-style">
                    <div class="pre-title">
                        <img src="assets/images/banner/bulb-2.png" alt="icon">
                        <span>Why Choose Us</span>
                    </div>
                    <h2 class="title">“ASEP your path to Success”
                    </h2>
                    <p class="post-title p-0 m-3">• Prof. Vinayak Antarkar being instructor for more than 2000
                        students, he is well aware of the examination requirements, mindset of the students and ways
                        and means to channelize their potential.</p>
                    <p class="post-title p-0 m-3">• Exhaustive study material is supplied which is adequate for the
                        students.</p>
                    <p class="post-title p-0 m-3">• Emphasis on overall academic and psychological development.</p>
                </div>
                <div class="why-choose-main-wrapper-1">
                    <!-- single choose reason -->
                    <div class="single-choose-reason-1">
                        <div class="icon">
                            <img src="assets/images/why-choose/icon/01.png" alt="icon">
                        </div>
                        <h6 class="title">Expert
                            Instructors</h6>
                    </div>
                    <!-- single choose reason end -->
                    <!-- single choose reason -->
                    <div class="single-choose-reason-1">
                        <div class="icon">
                            <img src="assets/images/why-choose/icon/02.png" alt="icon">
                        </div>
                        <h6 class="title">Interactive
                            Learning</h6>
                    </div>
                    <!-- single choose reason end -->
                    <!-- single choose reason -->
                    <div class="single-choose-reason-1">
                        <div class="icon">
                            <img src="assets/images/why-choose/icon/03.png" alt="icon">
                        </div>
                        <h6 class="title">Affordable
                            Learning</h6>
                    </div>
                    <!-- single choose reason end -->
                    <!-- single choose reason -->
                    <div class="single-choose-reason-1">
                        <div class="icon">
                            <img src="assets/images/why-choose/icon/04.png" alt="icon">
                        </div>
                        <h6 class="title">Career
                            Advance</h6>
                    </div>
                    <!-- single choose reason end -->
                    <!-- single choose reason -->
                    <div class="single-choose-reason-1">
                        <div class="icon">
                            <img src="assets/images/why-choose/icon/05.png" alt="icon">
                        </div>
                        <h6 class="title">Course
                            Selection</h6>
                    </div>
                    <!-- single choose reason end -->
                    <!-- single choose reason -->
                    <div class="single-choose-reason-1">
                        <div class="icon">
                            <img src="assets/images/why-choose/icon/06.png" alt="icon">
                        </div>
                        <h6 class="title">Support
                            Community</h6>
                    </div>
                    <!-- single choose reason end -->
                </div>
                <a routerLink="/courses" class="rts-btn btn-primary-white with-arrow">View All Course <i
                        class="fa-regular fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
    <div class="shape-image">
        <div class="shape one" data-speed="0.04" data-revert="true" style="transform: matrix(1, 0, 0, 1, -9.98, 9.68);"><img src="assets/images/15.png" alt="">
        </div>
        <div class="shape two" data-speed="0.04" style="transform: matrix(1, 0, 0, 1, 11.98, -7.68);"><img src="assets/images/banner-shape02.svg" alt="">
        </div>
        <div class="shape three" data-speed="0.04" style="transform: matrix(1, 0, 0, 1, 11.98, -7.68);"><img src="assets/images/16.png" alt=""></div>
    </div>
    
</div>