import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { courses } from 'src/app/cart/modal/checkout';
import { IndexService } from 'src/app/shared/services/index.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { IBCPayment } from '../modals/IBCPayment';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { AlertService } from 'src/app/shared/services/alert.service';
declare var Razorpay: any;
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  courses: any;
  apiURL = environment.url;
  category: any;
  categorycourse: any;
  dropData: any;
  selectedCategory: any
  createAccountForm!: FormGroup
  payIbcForm!: FormGroup
  submitted = false;
  ibc: any;
  ibcDetails: any;
  id: any;
  price: any;
  checkoutIBC!:IBCPayment[];
  
  Id = this.cryptojsService.decryptLocalUserId()
  Math: any;
  message: any = "Not yet stared";
  paymentId = "";
  error = "";

  options = {
    "key": "rzp_test_3zjfe3fNSCMqVA",
    "amount": "100",
    "name": "Sagar",
    "description": "",
    "image": "",
    "order_id": "",
    "ibc_id":"",
    "handler": function (response: any) {
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      window.dispatchEvent(event);
    },
    "prefill": {
      "name": "",
      "email": "",
      "contact": "",
      "ibc_id":"",
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#3399cc"
    }
  };


  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    this.message = "Success Payment";
    console.log(this.options.prefill.ibc_id,"ibc_id")
    console.log(this.options.prefill.email,"email")
    this.payIbc();
   this.ibcCheckout(event.detail.razorpay_payment_id);

  }


  constructor(private indexService: IndexService, private formBuilder: FormBuilder, private router: Router, private ActivateRoute: ActivatedRoute, private toastr: ToastrService, private alertservice: AlertService, private cryptojsService:CryptojsService) { }

  ngOnInit(): void {
    this.getAllCourses();
    this.getAllCategory();
    this.getIbc();
    this.createAccountForm = this.formBuilder.group({
               
      name: ['', Validators.required], 
      contactNo: ['', Validators.required,Validators.pattern(/^[0-9]{10}$/)],
      email: ['', Validators.required],
      categoryId: ['', Validators.required],
      courseId: ['', Validators.required],
      ibcid: ['',],
    });

    this.payIbcForm = this.formBuilder.group({
      ibC_Id: ['',],
      course_Id: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      contactNo: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
    })

  }

  get f() { return this.createAccountForm.controls; }


  getAllCourses() {
    this.courses = [];
    this.indexService.getCourse().subscribe(response => {
      //  console.log(response, 'coursedata');
      this.courses = response['items'];
      //console.log(this.courses);  
    }, err => {

    })
  }
  getAllCategory() {
    this.category = [];
    this.indexService.getCategory().subscribe(response => {
      this.category = response['items'];
      // console.log(this.category);  
    }, err => {

    })
  }
  getCategoryCourse(event: Event) {
    this.dropData = (event.target as HTMLInputElement).value;
    this.categorycourse = [];
    //console.log(id,'hhjgjhjhg');
    this.indexService.getCategoryCourse(this.dropData).subscribe(response => {
      this.categorycourse = response;
      // console.log(this.categorycourse,'categorycourse'); 
      // this.dropData= this.categorycourse.filter(x => x.categoryId == id)

    }, err => {

    })
  }

  getIbc() {
    this.ActivateRoute.params.subscribe(res => {
      this.ibc = res.Username.toString()
      // console.log(this.viewCourse);
      this.indexService.getIbc(this.ibc).subscribe(res => {
        this.ibcDetails = res;
        console.log("ibcDetails:", this.ibcDetails);
      })
    })
  }

  onSubmit() {

    this.submitted = true;
    let ibcId = this.ibcDetails.id
    this.createAccountForm.value.ibcid = ibcId;
    // stop here if form is invalid
    if (this.createAccountForm.invalid) {
      return;
    }
    // console.log(this.createAccountForm.value.ibcid = ibcId)
    this.indexService.IbcThrough(this.createAccountForm.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertservice.success('Registration Successful');
          this.createAccountForm.reset();
          this.submitted = false;
        },
        error: error => {
          this.alertservice.error("Something Went Wrong.");
        }
      });
  }

  courseId(id: any, price: any) {
    this.id = id;
    this.price = price
  }

  payIbc() {
    this.submitted = true;
    let ibC_Id = this.ibcDetails.id
    this.payIbcForm.value.ibC_Id = ibC_Id;
    this.payIbcForm.value.course_Id = this.id
    let amount = this.price

    // stop here if form is invalid
    if (this.payIbcForm.invalid) {
      return;
    }
    this.indexService.Ibcpay(this.payIbcForm.value)
      .pipe(first())
      .subscribe({
        
        next: () => {
          console.log(this.payIbcForm.value,'next');
          this.paymentId = '';
          this.error = '';
          this.options.amount = (parseInt(this.price.toString()) * 100).toString() //paise * 100 
          this.options.name = this.payIbcForm.value.name;
          this.options.prefill.email = this.payIbcForm.value.email;         
          this.options.prefill.contact = this.payIbcForm.value.contactNo;
          this.options.prefill.ibc_id =  this.ibcDetails.id;
          var rzp1 = new Razorpay(this.options);
          rzp1.open();
          rzp1.on('payment.failed', function (response: any) {
            //this.message = "Payment Failed";
            // Todo - store this information in the server
            console.log(response.error.code);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
            //this.error = response.error.reason;
          }
          );
          this.payIbcForm.reset();
          this.submitted = false;
        },
        
        error: error => {
          this.alertservice.error("Something Went Wrong.");
        }
      });
  }

  ibcCheckout(id:any) {
    // console.log(id);
    // this.submitted = true;
    // if (this.checkoutForm.invalid) {
    //   return;
    // }
    console.log(this.payIbcForm.value, "Mine");

    var formData = this.payIbcForm.value;
    var couresData:courses[] = [];
   
      var course = new courses();
      course.course_Id = this.payIbcForm.value.course_Id,
        course.payment_Data_ID = '',
        course.offer_Percentage = 0,
        course.actual_Price = 0,
        course.offer_Price = 0;
      couresData.push(course);

     
 
    formData['payment_Id'] = id;
    formData['courses'] = couresData;
    formData['ibC_Id'] = this.ibcDetails.id;
    formData['client_Id'] = this.cryptojsService.decryptLocalUserId()
    formData['firstname'] = '';
    formData['lastname'] = '';
    formData['country'] = '';
    formData['state'] = '';
    formData['address'] = '';
    formData['city'] = '';
    formData['postalCode'] = '';
    formData['phoneNumber'] = '';
    formData['email'] = this.options.prefill.email;
    formData['additionalInfo'] = ''; 
    formData['comanyName'] = '';
    formData['gstNo'] = '';    
    formData['paymentMode'] = '';
    formData['totalOrderAmount'] = parseFloat(this.price);

     delete formData['course_Id'];
      delete formData['name'];
      delete formData['date'];
      delete formData['contactNo'];

           
console.log(formData,'jkhkhjh');
    this.indexService.checkout(formData).subscribe(result => {
      Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'Thank you for buying Course.' + ' ' + 'Total Amount You Paid' + ' ' + ' ' + this.price,
        showConfirmButton: true,
        timer: 3000,
      })
      this.router.navigate(['/course']);
    }, (err) => Swal.fire({
      position: 'top',
      icon: 'warning',
      title: err,
      showConfirmButton: true,
      timer: 3000,
    }));
  }

  setDefaultImage(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '../../../../assets/defaultimg/defaultMainImg.png';
  }

}
