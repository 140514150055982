<header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax data-speed=".8"
  style="background-image: url(assets/images/);">
  <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-6">
              <div class="breadcrumb-main-wrapper">
                <h1 class="title">My Profile</h1>

                <div class="pagination-wrapper  c-pointer">
                  <a routerLink="/index">Home</a>
                  <i class="fa-regular fa-chevron-right"></i>
                  <a class="active">My Profile</a>
                </div>

              </div>

            </div>
            <div class="col-6">
              <div class="breadcrumb-img-book">
                <img src="\assets\images\breadcrumb\book.png" alt="">
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</header>

<div class="container my-8">
  <div class="row">
    <div class="col-md-4 col-xl-3 mb-6">


      <div class="nav flex-column nav-vertical border rounded px-6 py-4" id="v-pills-tab" role="tablist"
        aria-orientation="vertical">

        <div class="d-flex align-items-center justify-content-center">
          <div class="border rounded-circle d-inline-block mb-4 mb-md-0 me-4">
            <div class="p-1 pro-icon">
              <img src="assets/images/profile-icon-9.png" alt="..." class="rounded-circle" width="52" height="52">
            </div>
          </div>

          <div class="mb-4 mb-md-0">
            <a href="blog-single.html" class="d-block">
              <h6 class="mb-0">{{ name }} {{ surname }}</h6>
            </a>
            <span class="font-size-sm">{{ email }}</span>
          </div>
        </div>
        <a class="nav-link active p-0 mt-3 mb-3" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile"
          role="tab" aria-controls="v-pills-profile" aria-selected="true"><i class="fa-regular fa-user me-3"></i>
          Profile</a>
        <a class="nav-link  p-0 mt-3 mb-3 " id="v-pills-courses-tab" data-bs-toggle="pill" href="#v-pills-courses"
          role="tab" aria-controls="v-pills-courses" aria-selected="true"><i class="fa-solid fa-book me-3"></i>
          Courses</a>

        <a class="nav-link p-0 mt-3 mb-3" id="v-pills-change-pass-tab" data-bs-toggle="pill" href="#v-pills-change-pass"
          role="tab" aria-controls="v-pills-change-pass" aria-selected="true"><i
            class="fa-solid fa-lock me-3"></i>Change
          Password</a>
        <a class="nav-link p-0 mt-3 mb-3" id="v-pills-reviews-tab" (click)="myReviews()" data-bs-toggle="pill"
          href="#v-pills-reviews" role="tab" aria-controls="v-pills-reviews" aria-selected="false"><i
            class="fa-regular fa-heart"></i>
          Wishlist</a>
      </div>
    </div>
    <div class="col-md-8 col-xl-9">
      <div class="tab-content" id="v-pills-tabContent">
        <div class="tab-pane fade" id="v-pills-dashboard" role="tabpanel" aria-labelledby="v-pills-dashboard-tab">
          <h3 class="">1. Terms</h3>
          <p class="mb-6">Do you want to become a UI/UX designer but you don't know where to start? This
            course will allow you to develop your user interface design skills and you can add UI
            designer to your CV and start getting clients for your skills.</p>
        </div>
        <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel"
          aria-labelledby="v-pills-profile-tab">
          <div class="card" style="display: flex; align-items: center; justify-content: center;">
            <h3 class="">My Profile</h3>
          </div>

          <div class="col-md-12 col-xl-12 mx-auto">

            <form class=" bg-white border border-gray-200 p-5" [formGroup]="editProfile" (ngSubmit)="EditProfile()">

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="modalSigninusername">
                      First Name
                    </label>
                    <input type="text" formControlName="name" name="name"
                      class="form-control form-control-sm border border-dark" id="modalSigninusername" placeholder="">
                    <div *ngIf="editProfile.controls['name'].invalid && editProfile.controls['name'].touched">
                      First Name is required
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="modalSigninusername">
                      Last Name
                    </label>
                    <input type="text" formControlName="surname" name="surname"
                      class="form-control form-control-sm border border-dark" id="modalSigninusername" placeholder="">
                    <div *ngIf="editProfile.controls['surname'].invalid && editProfile.controls['surname'].touched">
                      Last Name is required
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-12 col-md-6">

                  <div class="form-group mb-3">
                    <label for="email">
                      Email
                    </label>
                    <input type="text" formControlName="email" name="email"
                      class="form-control form-control-sm border border-dark" id="email" placeholder="">
                    <div *ngIf="editProfile.get('email')?.invalid && editProfile.get('email')?.touched">
                      <span class="text-danger" *ngIf="editProfile.get('email')?.errors?.required">Email is
                        required.</span>
                      <span class="text-danger" *ngIf="editProfile.get('email')?.errors?.pattern">Email must
                        contain '&#64;' and end with '.com'.</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">

                  <div class="form-group mb-3">
                    <label for="modalSigninphone">
                      Mobile No
                    </label>
                    <input type="text" maxlength="10" formControlName="phoneNumber" name="phoneNumber"
                      class="form-control form-control-sm border border-dark" id="modalSigninphone" placeholder="">
                    <div *ngIf="editProfile.get('phoneNumber')?.invalid && editProfile.get('phoneNumber')?.touched">
                      <span class="text-danger" *ngIf="editProfile.get('phoneNumber')?.errors?.required">Number is
                        required.</span>
                      <span class="text-danger" *ngIf="editProfile.get('phoneNumber')?.errors?.pattern">Only numbers are
                        allowed.</span>
                      <span class="text-danger" *ngIf="editProfile.get('phoneNumber')?.errors?.maxlength">Maximum length
                        is
                        10.</span>
                      <span class="text-danger" *ngIf="editProfile.get('phoneNumber')?.errors?.minlength">Manimum length
                        is
                        10.</span>
                    </div>
                  </div>
                </div>
              </div>


              <div class="d-flex justify-content-end mt-3" >
                <button class="rts-btn btn-primary btn-sm btn-wide text-right " type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>


        </div>
        <div class="tab-pane fade show " id="v-pills-courses" role="tabpanel" aria-labelledby="v-pills-courses-tab">
          <div class="card" style="display: flex; align-items: center; justify-content: center;">
            <h3 class="">My Courses</h3>
          </div>

          <div class="col-md-12 col-xl-12 mx-auto">

            <form class=" bg-white border border-gray-200 p-5" [formGroup]="editProfile" (ngSubmit)="EditProfile()">

              <div class="row" *ngIf="subscription.length > 0">
                <div class="col-lg-4 col-md-12" *ngFor="let sub of subscription">
                  <div class="rts-single-course sticky-content">
                    <a routerLink="/courses/course-details/{{sub?.courseId}}" class="thumbnail">
                      <img [src]="apiURL+'/'+sub?.bannerImg" alt="course" (error)="setDefaultImage($event)">
                    </a>
                    <div class="save-icon" data-bs-toggle="modal" data-bs-target="#exampleModal-login">
                      <!-- <i class="fa-sharp fa-light fa-bookmark"></i> -->
                    </div>
                    <div class="tags-area-wrapper">
                      <div class="single-tag">
                        <span>{{sub?.categoryName}}</span>
                      </div>
                    </div>
                    <a href="single-course-two.html">
                      <h5 class="title">{{sub?.courseName}}</h5>
                    </a>
                    <div class="lesson-studente">
                      <div class="lesson">
                        <i class="fa-light fa-calendar-lines-pen"></i>
                        <span>{{sub?.noOfLessons}} Lessons</span>
                      </div>
                      <div class="lesson">
                        <i class="fa-light fa-user-group"></i>
                        <span>{{sub?.noOfStudents}} Students</span>
                      </div>
                    </div>
                    <div>
                      <div class="row">
                        <!-- <div class="col-sm">
                          <div class="rating-and-price">
                            <div class="price-area">
                              <div class="not price">
                                &#8377;{{sub?.actualPrice}}
                              </div>
                              <div class="price">
                                &#8377;{{sub?.price}}
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-sm">
                          <div class="rating-and-price">
                            <div class="price-area">
                              <div>
                                By {{maxLength(sub?.authorName,20)}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-grid mt-3">
                        <button class="btn btn-primary" style="background: #553cdf;" type="button"
                          data-bs-toggle="modal" data-bs-target="#feedbackModal"
                          (click)="getCourseId(sub)">Feedback</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row" style="display: flex; justify-content: center;">
                <div class="col-lg-4 col-md-12" *ngIf="subscription.length == 0">
                  <img src="\assets\images\course\Course-not-Found.jpg" alt="">
                </div>
              </div>

            </form>
          </div>


        </div>
        <div class="tab-pane fade " id="toggle_pwd" id="v-pills-change-pass" role="tabpanel"
          aria-labelledby="v-pills-change-pass-tab">
          <div class="card" style="display: flex; align-items: center; justify-content: center;">
            <h3 class="">Change Password</h3>
          </div>
          <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()"
            class=" bg-white border border-gray-200 p-5">
            <div class="form-group mb-3">
              <label for="modalSigninEmail1">
                Old Password
              </label>

              <input [type]="showpassword ? 'text' : 'password'" formControlName="currentPassword" id="currentPassword"
                name="currentPassword" class="form-control form-control-sm border border-dark" placeholder="">
              <span id="toggle_pwdold" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon"
                (click)="displayPassWord()"></span>
              <div
                *ngIf="changePasswordForm.controls['currentPassword'].invalid && changePasswordForm.controls['currentPassword'].touched">
                Current Password is required
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="modalSigninPassword1">
                New Password
              </label>
              <input [type]="shownewpassword ? 'text' : 'password'" formControlName="newPassword" id="newPassword"
                name="newPassword" class="form-control form-control-sm border border-dark" placeholder="">
              <span id="toggle_pwdnew" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon"
                (click)="displayNewPassWord()"></span>
              <div
                *ngIf="changePasswordForm.controls['newPassword'].invalid && changePasswordForm.controls['newPassword'].touched">
                New Password is required
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="rts-btn btn-primary btn-sm btn-wide text-right " type="submit">
                Submit
              </button>

            </div>
          </form>
        </div>
        <div class="tab-pane fade " id="v-pills-subscription" role="tabpanel"
          aria-labelledby="v-pills-subscription-tab">
          <h3 class="">My Subscription</h3>
          <ul class="list-unstyled mb-0">
            <ng-container *ngIf="subscription">
              <ng-container *ngFor="let s of subscription;let i=index">
                <li class="media mb-6 d-flex">
                  <a [routerLink]="['/lesson-details/'+ s.courseId.toString() +'']" class="w-100p d-block me-5">
                    <img src="{{ apiURL }}/{{ s.bannerImg }}" alt="..." class="avatar-img rounded-lg h-90p w-100p">
                  </a>
                  <div class="media-body flex-grow-1">
                    <a href="course-single-v5.html" class="d-block">
                      <h6 class="line-clamp-2 mb-1">{{s.courseName}}</h6>
                    </a>

                    <ins class="h6 mb-0 ">₹{{s.price}}</ins>
                    <a [routerLink]="['/lesson-details/'+ s.courseId.toString() +'']"
                      class="line-clamp-2 mb-3 badge badge-lg badge-dark text-white w-100p fw-normal ">Start
                      Course</a>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
        <div class="tab-pane fade" id="v-pills-reviews" role="tabpanel" aria-labelledby="v-pills-reviews-tab">
          <div class=" ms-cart-collaterals cart-collaterals">
            <div class="card" style="display: flex; align-items: center; justify-content: center;">
              <h3 class="">My Wishlist</h3>
            </div>

            <ul class="list-unstyled pt-2">
              <div class="row" *ngIf="wishlist.length > 0">
                <div class="col-lg-4 col-md-12" *ngFor="let wish of wishlist">
                  <div class="rts-single-course sticky-content">
                    <a class="thumbnail">
                      <img [src]="apiURL+'/'+wish?.bannerImg" alt="course" (error)="setDefaultImage($event)">
                      <div class="heart-icon" (click)="toggleHeart(wish.id)" [ngClass]="{'active': isHeartActive}">
                        <i class="fa fa-heart"></i>
                      </div>
                    </a>
                    <div class="save-icon" data-bs-toggle="modal">
                      <!-- <i class="fa-sharp fa-light fa-bookmark"></i> -->
                    </div>
                    <div class="tags-area-wrapper">
                      <div class="single-tag">
                        <span>{{wish?.categoryName}}</span>
                      </div>
                    </div>
                    <a href="single-course-two.html">
                      <h5 class="title">{{maxLength(wish?.name,22)}}</h5>
                    </a>
                    <div class="lesson-studente">
                      <div class="lesson">
                        <i class="fa-light fa-calendar-lines-pen"></i>
                        <span>{{wish?.lessonCount}} Lessons</span>
                      </div>
                      <div class="lesson">
                        <i class="fa-light fa-user-group"></i>
                        <span>{{wish?.studentCount}} Students</span>
                      </div>
                    </div>



                    <div class="rating-and-price">

                      <div class="price-area">
                        <!-- <div class="not price">
                          &#8377;{{wish?.actual_Price}}
                        </div> -->
                        <div class="price">
                          &#8377;{{wish?.offer_Price}}
                        </div>
                      </div>
                      <div class="price-area">
                        <p><b>By</b> {{maxLength(wish?.authorName,14)}}</p>
                      </div>
                    </div>


                    <div class="mt-3" routerLink="/courses/course-details/{{wish.course_Id}}">
                      <button class="btn btn-primary" style="background: #553cdf;">Buy Now</button>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row" style="display: flex; justify-content: center;">
                <div class="col-lg-4 col-md-12 text-center" *ngIf="wishlist.length == 0">
                  <img src="\assets\images\course\Course-not-Found.jpg" alt="">
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>

<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>

<div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form [formGroup]="ratingCommentForm" (ngSubmit)="onRatingCommentForm()">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="feedbackModalLabel">Feedback</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="feedbackId"
            (click)="closeFeedbackModal()"></button>
        </div>
        <div class="modal-body">

          <p>Please rate your experience:</p>

          <div class=" star-rating-container d-flex justify-content-center mb-5">
            <ngx-star-rating class="d-flex justify-content-center" [id]="'ratingg'"
              formControlName="ratings"></ngx-star-rating>

            <div *ngIf="
            ratingCommentForm.get('ratings')?.invalid &&
            ratingCommentForm.get('ratings')?.touched
          " style="color: red;">
              Rating Required
            </div>
          </div>

          <textarea class="form-control mt-3" rows="4" placeholder="Leave your feedback..."
            formControlName="comments"></textarea>
          <div *ngIf="
            ratingCommentForm.get('comments')?.invalid &&
            ratingCommentForm.get('comments')?.touched
          " style="color: red;">
            Comments Required
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="rts-btn btn-primary btn-sm " data-bs-dismiss="modal"
            (click)="closeFeedbackModal()">Close</button>
          <button type="submit" type="submit" class="rts-btn btn-primary btn-sm">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>