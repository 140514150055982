import { Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm!: FormGroup;
  submitted = false;

  userPresent: boolean = false;
  modalToggle = 'modal'; 
  modalTarget = '#admission-form-modal'; 
  buttonId = 'admission-form-btn'; 
  attributesAdded: boolean = false; 

  constructor(private indexService: IndexService,private toastr: ToastrService, private alertservice: AlertService,
    private formBuilder: FormBuilder,private cryptojsService:CryptojsService) { }

  ngOnInit(): void {
   this.contactUsFormFunction()
  }

  contactUsFormFunction(){
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,  Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      phone_Number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      message: ['', Validators.required],
    });
  }
  get f() { return this.contactUsForm.controls; }

  contactUs() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched()
      return;
    }
   
    this.indexService.contactUs(this.contactUsForm.value)
      .subscribe(result => {
        this.alertservice.success("Form submitted Successfully.")
        this.contactUsForm.reset();
        this.submitted = false;
      });
     
  }

  checkUserPresence(callback: (present: boolean) => void) {
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      this.userPresent = status;
      callback(this.userPresent);
    });
  }

  handleClick() {
    this.checkUserPresence((present) => {
      if (present) {
        this.attributesAdded = true; 
      } else {
        this.alertservice.warning('Please login for applying admission form')
      }
    });
  }

  onActivate(){
    this.indexService.onActivate()
  }
  
}

