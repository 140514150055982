import { Component, NgZone, DoCheck, OnInit, Pipe, PipeTransform } from '@angular/core';
import { CourseService } from 'src/app/courses/services/course.service'
import { IndexService } from 'src/app/shared/services/index.service'
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Course } from 'src/app/courses/modals/course';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'src/app/shared/services/cart.service';
import { cart } from 'src/app/shared/modals/cart';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AccountService } from 'src/app/shared/services/account.service';
import { AlertService } from 'src/app/shared/services/alert.service';

declare var YT: any;

declare var bootstrap: any;
@Component({
  selector: 'app-couse-single',
  templateUrl: './course-single.component.html',
  styleUrls: ['./course-single.component.scss']
})

export class CourseSingleComponent implements OnInit {
  Id: any;
  apiURL = environment.url
  addreviewsForm!: FormGroup;
  course: any;
  detailsOfCourse: any;
  detailsOfCourseInfo: any;
  detailsOfCourseAuthorWiseStudentsCount: any;
  detailsOfCourseExpert: any;
  alerdyAddedToCart: boolean = false;
  phoneNumber: string = '8828228330'; 
  defaultMessage: string = '“Hello, I would like to know more about the course.”';
  videoUrl: string = '';
  sanitizedVideoUrl: SafeResourceUrl = '';
  isVideoPlaying: boolean = false;
  prevideoUrl: string = '';
  presanitizedVideoUrl: SafeResourceUrl = '';
  playStartTime!: number;
  player: any;
  watchDuration = 0;
  courseFullDetails: any = [];
  lessionFirstVideoLink: any;
  lessionFirstVideoLinkPaid: boolean = false;
  myCourses: any = []
  plainTextDescription: any;
  courseComments:any=[]
  faqArrays:any=[]
  pdfNotesDescription:any=null;
  startTimeInSeconds:number=0
  watchTimeInSeconds:number=0;
  

  //video percentage
  watchTimeInSecond:any=0;
  videoPercentageObject:any={
    videoId:null,
    courseId:null,
    userId:this.cryptojsService.decryptLocalUserId(),
    watchedSeconds:this.watchTimeInSecond
  };
  previewPresanitizedVideoUrl!: SafeResourceUrl;
  previewPlayer!: any;
  previewVideoStartTime: number = 0;
  previewVideoEndTime: number = 0;
  previewVideoWatchTimeInSecond:number=0;
  additionalData:any=[]

  constructor(private courseService: CourseService,
    private indexService: IndexService, private activateRoute: ActivatedRoute,private alertservice: AlertService,
    private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router,
    private cartService: CartService,
    private cryptojsService: CryptojsService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.getUserBuyCourse()
    this.getCourseId()
    this.checkCoursePresentOrNot()
    
    //stop playlist when video is end
    window['onYouTubeIframeAPIReady'] = () => {
      this.createPlayer();
    };

    if (!window['YT']) {
      window['onYouTubeIframeAPIReady'] = () => this.initializePlayer();
    } else {
      this.initializePlayer();
    }

  }


  AdditionalData(id:any){
    this.courseService.courseAdditionalData(id).subscribe((resCourse:any)=>{
      if(resCourse.status){
        this.additionalData=resCourse.data
        console.log("get data",this.additionalData);
      }else{
        this.additionalData=[]
      }
    })
  }

  courseCommentsCall(id:any){
    this.courseService.courseApprovedComments(id).subscribe((resCourse:any)=>{
      if(resCourse){
        this.courseComments=resCourse
      }else{
        this.courseComments=[]
      }
      
    })
  }

  getUserBuyCourse() {
    let user_info = this.cryptojsService.decryptLocalUserId()
    this.accountService.mySubscription(user_info).subscribe((resMyCourses: any) => {
      if (resMyCourses) {
        this.myCourses = resMyCourses
      } else {
        this.myCourses = []
      }
    })
  }

  //stop playlist when video is end
  createPlayer() {
    this.player = new YT.Player('ytPlayer', {
      events: {
        onStateChange: (event: any) => this.onPlayerStateChange(event),
      },
    });
  }

  private initializePlayer() {
    this.player = new YT.Player('ytPlayer', {
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
  }

  closeVideo() {
    if (this.player) {
      this.player.stopVideo();
    }
    this.isVideoPlaying = false;
    this.calculateWatchDuration();
  }

  onPlayerStateChange(event: any) {
    if (event.data === YT.PlayerState.ENDED) {
      this.isVideoPlaying = false;
    }
  
    this.ngZone.run(() => {
      switch (event.data) {
        case YT.PlayerState.PLAYING:
          this.startTimeInSeconds = this.player.getCurrentTime(); 
          break;
  
        case YT.PlayerState.PAUSED:
        case YT.PlayerState.ENDED:
          this.calculateWatchDuration();
          break;
      }
    });
  }

  calculateWatchDuration() {
    if (this.startTimeInSeconds > 0) {
      const stopTimeInSeconds = this.player.getCurrentTime();
      const sessionDuration = stopTimeInSeconds - this.startTimeInSeconds; 
      if (sessionDuration > 0) {
        this.watchTimeInSeconds = Math.floor(sessionDuration); 
        this.videoPercentageObject.watchedSeconds = this.startTimeInSeconds;
        console.log(`Watch Duration: ${this.watchTimeInSeconds} seconds`, this.videoPercentageObject);
        this.callVideoPercentageCall();
      }
  
      // Reset start time for the next session
      this.startTimeInSeconds = 0;
    }
  }

  callVideoPercentageCall(){
    if(this.cryptojsService.decryptLocalUserId() && this.lessionFirstVideoLinkPaid){
      console.log("IF LAST",this.videoPercentageObject)
      this.courseService.addVideoPercentage(this.videoPercentageObject).subscribe((resVideoPercetage:any)=>{
        console.log("get res",resVideoPercetage)
      })
    }
  }

  formatDuration(durationMs: number): string {
  const totalSeconds = Math.floor(durationMs / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours}h ${minutes}m ${seconds}s`;
}

  getCourseId() {
    this.activateRoute.params.subscribe(params => {
      const id: any = params['id'];
      if (id) {
        this.getCourseDetails(id);
        this.getCourseFullDetails(id);
        this.courseCommentsCall(id)
        this.AdditionalData(id)
      }
    });
  }

  getCourseFullDetails(id: any) {
    this.courseService.LessonDetails(id).subscribe((resLessonDetails: any) => {
      if (resLessonDetails && resLessonDetails.length > 0) {
        this.courseFullDetails = resLessonDetails[0];
        this.videoPercentageObject.courseId=this.courseFullDetails?.id
        this.videoPercentageObject.videoId=this.courseFullDetails?.lessons[0].videoRow[0].id
        
        console.log("get course full details", this.courseFullDetails);
        
        this.plainTextDescription = this.removeHtmlTags(this.courseFullDetails?.description);
        const firstLesson = this.courseFullDetails?.lessons?.[0];
        const firstVideoRow = firstLesson?.videoRow?.[0];
        this.lessionFirstVideoLink = firstVideoRow?.videoURL || '';
        for (let i = 0; i < (this.courseFullDetails.lessons?.length || 0); i++) {
          let videoRowArray: any = this.courseFullDetails.lessons[i]?.videoRow;
          if (videoRowArray && Array.isArray(videoRowArray)) {
            for (let j = 0; j < videoRowArray.length; j++) {
              if (this.myCourses.length > 0) {
                const isIdPresent = this.myCourses.some(
                  (obj: any) =>
                    obj?.courseId?.toLowerCase() ===
                    this.courseFullDetails?.id?.toLowerCase()
                );
                videoRowArray[j].paid = isIdPresent;
              } else {
                videoRowArray[j].paid = false;
              }
            }
          }
        }
        this.lessionFirstVideoLinkPaid = firstVideoRow?.paid || false;
      } else {
        this.courseFullDetails = [];
        this.plainTextDescription = "Description not found"
      }
    })
  }

  removeHtmlTags(htmlString: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
  }


  goToMainScreen(selectedLink: any) {
    if (selectedLink?.videoURL) {
      if (this.player && this.isVideoPlaying) {
        this.player.stopVideo(); 
        this.isVideoPlaying = false; 
      }
      this.lessionFirstVideoLink = selectedLink?.videoURL;
      this.videoPercentageObject.videoId = selectedLink.id;
      const element = document.getElementById('MainVideoSection');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      this.openModal(this.lessionFirstVideoLink, selectedLink.paid);
    }
  }
  getCourseDetails(id: any) {
    this.cartService.checkCourseInsideCart({ id: id })
    this.courseService.ViewMainCourse(id).subscribe((resViewMainCourse: any) => {
      if (resViewMainCourse) {
        resViewMainCourse.quantity = 1;
        this.detailsOfCourse = resViewMainCourse
        let authorId = this.detailsOfCourse?.authorId
        this.indexService.getExpert(authorId).subscribe((resExpert: any) => {
          if (resExpert) {
            this.detailsOfCourseExpert = resExpert
            console.log("get details",this.detailsOfCourseExpert);
            
          }
        })
      }
    })

    this.courseService.ViewCourse(id).subscribe((resViewCourse: any) => {
      if (resViewCourse.length > 0) {
        this.detailsOfCourseInfo = resViewCourse[0]
        console.log("get data-->",this.detailsOfCourseInfo);
        
      }
    })

    this.courseService.courseInstructor(id).subscribe((resInstructor: any) => {
      if (resInstructor.length > 0) {
        this.detailsOfCourseAuthorWiseStudentsCount = resInstructor[0]
      }
    })


  }


  checkCoursePresentOrNot() {
    this.cartService.coursePresentSignal$.subscribe((resCoursePresentOrNot: any) => {
      this.alerdyAddedToCart = resCoursePresentOrNot
    })
  }
  buynow() {
    let user_info = this.cryptojsService.decryptLocalUserId()
    let access_token = this.cryptojsService.decryptLocalAccessToken()
    if (user_info && access_token) {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/login']);
    }

  }

 


  addToCart(course: any) {
    this.cartService.cartService(course)
  }




  addReviewToCourse() {
    if (this.addreviewsForm.invalid) {
      return;
    }
    let approvalFlag = true
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.activateRoute.snapshot.paramMap.get('Id');
  }


  addToWishlist() {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.activateRoute.snapshot.paramMap.get('Id');

    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        this.alertservice.success("Course added successfully to Wishlist")
      }, err => {
        if (err.status === 409) {
          this.alertservice.error("Course already in Wishlist.");
        }
      });
  }
  goViewCart() {
    console.log("get user login key",this.cryptojsService.decryptLocalUserId());
    if(this.cryptojsService.decryptLocalUserId() == null){
      this.alertservice.warning("Please login first to buy courses!")
      return
    }
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      console.log("get data!!!!!!!!",status,this.cartService.cartCoursePresent());
      if (status && this.cartService.cartCoursePresent()) {
        this.router.navigateByUrl('/view-cart')
      } else {
        this.alertservice.warning("Please add at least one course to the cart!")
      }
    }
    )

  }

  onActivate() {
    this.indexService.onActivate()
  }

  getWhatsAppLink(): string {
    return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.defaultMessage)}`;
  }


  openModal(url: string, paid: any) {

    if (paid) {
      const youtubeEmbedUrl = this.getEmbedUrl(url);
      this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeEmbedUrl);
      this.isVideoPlaying = true;
      setTimeout(() => this.createPlayer(), 0);
    } else {
      const youtubeEmbedUrl = this.getEmbedUrl(url);
      this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeEmbedUrl);
      this.isVideoPlaying = true;

      setTimeout(() => {
        this.stopVideoAndShowImage();
        this.alertservice.warning('This is a paid video. Please buy the course to access this lesson');
      }, 15000);  // 15 seconds (15000 milliseconds)

      setTimeout(() => this.createPlayer(), 0);
    }
  }

  stopVideoAndShowImage() {
    this.isVideoPlaying = false;
    if (this.player) {
      this.player.stopVideo(); // Stop the video after 15 seconds
    }
  }

  getEmbedUrl(url: string): string {
    const videoId = url.split('v=')[1];
    return `https://www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0&modestbranding=1&showinfo=0&controls=1&autoplay=1`;
  }


  preopenModal(url: string) {
    const youtubeEmbedUrl = this.getEmbedUrl(url);
    this.presanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeEmbedUrl);
    const modalElement = document.getElementById('videoModal');
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
      setTimeout(() => {
        this.previewPlayer = new YT.Player('ytPlayerPreview', {
          events: {
            'onStateChange': (event: any) => this.onPlayerStateChangee(event)
          }
        });
      }, 0);

      const cleanUpListener = () => {
        this.onModalClose();
        modalElement.removeEventListener('hide.bs.modal', cleanUpListener);
      };

      modalElement.addEventListener('hide.bs.modal', cleanUpListener);

    }
  }


  onPlayerStateChangee(event: any) {
    if (!this.previewPlayer) {
      console.error('YouTube Player not initialized yet.');
      return;
    }
  
    if (event.data === YT.PlayerState.PLAYING) {
      if (!this.previewVideoStartTime) {
        this.previewVideoStartTime = this.previewPlayer.getCurrentTime(); 
       
      }
    } else if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
      this.previewVideoWatchTimeInSecond = this.previewPlayer.getCurrentTime();
      console.log('End Time:', this.previewVideoWatchTimeInSecond, 'seconds');
     
      if (event.data === YT.PlayerState.ENDED) {
        this.previewVideoStartTime = 0;
      }

      this.videoPercentageObject.watchedSeconds=this.previewVideoWatchTimeInSecond 
      this.callVideoPercentageCall()
    }
  }

  checkPaid(checkPaid: any) {
    console.log("get data//////",checkPaid);
    this.videoPercentageObject.videoId= checkPaid.id  
    if (!checkPaid.paid) {
      setTimeout(() => {
        document.getElementById('closeLessionModel')?.click()
        this.alertservice.warning("This is a paid video. Please buy the course to access this lesson")
      }, 15000)
    }
  }

  onModalClose() {

    this.presanitizedVideoUrl = '';
    const iframe = document.querySelector('#videoModal iframe') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = '';
    }
  }

  downloadPdf() {
    const pdfUrl = 'https://nlsblog.org/wp-content/uploads/2020/06/true-pdf-sample-1.pdf';
    const pdfName = 'sample.pdf';
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up the link
        window.URL.revokeObjectURL(url); // Release the memory
      })
      .catch(err => console.error('Error fetching PDF:', err));
  }


  addFAQ(faq:any){
    if(faq.length == 0){
      this.alertservice.error("No faq found, please try later")
      this.faqArrays=[]
    }else{
      document.getElementById('faqButton')?.click()
      this.faqArrays=faq
    }
  }

  downloadPDF(pdfPath: string | undefined){
    if(this.lessionFirstVideoLinkPaid){
      if (!pdfPath) {
        console.error('No PDF path provided');
        return;
      }
      const fullUrl = this.apiURL+`${pdfPath}`;
      const anchor = document.createElement('a');
      anchor.href = fullUrl;
      anchor.download = pdfPath.split('/').pop() || 'download.pdf'; 
      anchor.target = '_blank'; 
      anchor.click();
    }else{
      this.alertservice.error("You need to buy the lesson to download the PDF.")
    }
  }

  addPDFDescription(notes:any){
    console.log("get data",notes);
    if(this.lessionFirstVideoLinkPaid){
      if(notes){
        document.getElementById('pdfNotesButton')?.click()
        this.pdfNotesDescription=notes
      }else{
        this.pdfNotesDescription=null
      }
    }else{
      this.alertservice.error("You need to buy the lesson to view the PDF Notes.")
    }
  }

  setDefaultImage(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '../../../../assets/defaultimg/defaultMainImg.png';
  }


  getDocsForMaterial(document:any){
    console.log("get documents",document);
    if(this.lessionFirstVideoLinkPaid){
      if (!document) {
        console.error('No Document path provided');
        return;
      }
      console.log("get data",this.apiURL+`${document}`);
      
      const fullUrl = this.apiURL+`${document}`;
      const anchor = document.createElement('a');
      anchor.href = fullUrl;
      anchor.download = document.split('/').pop() || 'materialdocs.pdf'; 
      anchor.target = '_blank'; 
      anchor.click();
    }else{
      this.alertservice.error("You need to buy the lesson to download the material includes documents.")
    }
  }

}



