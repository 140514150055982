import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IndexService } from '../../services/index.service';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  confirmPasswordForm!: FormGroup;
  bookAppointmentForm !:FormGroup;
  bookAppointmentDateTimeForm !:FormGroup;
  form!: FormGroup;
  loading = false;
  submitted = false;
  phoneNumber: string = '8828228330';  // Enter the phone number in international format
  defaultMessage: string = '“Hello, I would like to know more about the course.”';

  showOtpInput: boolean = false;
  showConfimPassword:boolean=false;
  showPassword = false;
  bookAppointmentTimeSlotArray:any=[
    {name:'09:00 to 10:00'},
    {name:'10:00 to 11:00'},
    {name:'11:00 to 12:00'},
    {name:'12:00 to 13:00'},
    {name:'13:00 to 14:00'},
    {name:'14:00 to 15:00'}
  ]
  bookAppointmentLastForm:any={};

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private alertservice: AlertService, private IndexService: IndexService,private accountService:AccountService) { }

  ngOnInit(): void {
    this.createResetPasswordForm()
    this.createConfirmPasswordForm()
    this.createBookAppointmentForm()
    this.createBookAppointmentDateTimeForm()
    this.form = this.formBuilder.group({
      sendmail: ['', Validators.required, Validators.email],
    });
  }

  createBookAppointmentForm(){
    this.bookAppointmentForm=this.formBuilder.group({
      name:['',[Validators.required]],
      email:['',[Validators.required]],
      mobileno:['',[Validators.required]],
      counsellingtype:['',[Validators.required]],
    })
  }

  createBookAppointmentDateTimeForm(){
    this.bookAppointmentDateTimeForm=this.formBuilder.group({
      date:['',[Validators.required]],
      timeslot:['',[Validators.required]]
    })
  }

 
  get f() { return this.form.controls; }


  togglePasswordVisibility(){
    this.showPassword = !this.showPassword;
  }


  getWhatsAppLink(): string {
    return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.defaultMessage)}`;
  }


  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.IndexService.sendMail(this.form.value)
      .subscribe({
        next: () => {

          this.alertservice.success('Thank you for subscribe...');
         

        },
        error: error => {
          this.alertservice.error(error);
          this.loading = false;
        }
      });
  }


  createResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      emailorphonenumber: ['', Validators.required],
    })
  }

  setOTPFlag(){
    this.showOtpInput = false;
  }

  createConfirmPasswordForm() {
    this.confirmPasswordForm = this.formBuilder.group({
      mobile: ['', Validators.required],
      confirmpassword: ['', [Validators.required]],
      otp:['', [Validators.required]]
    })
  }

  showResetPasswordForm() {

    if(this.resetPasswordForm.valid){
      this.accountService.forgetPassword(this.resetPasswordForm.value.emailorphonenumber).subscribe((resOTP:any)=>{
        if(resOTP.status){
          this.showOtpInput = true;
          document.getElementById('resetPasswordID')?.click();
          document.getElementById('confirmPasswordID')?.click();
          this.confirmPasswordForm.controls['mobile'].setValue(this.resetPasswordForm.value.emailorphonenumber);
          this.alertservice.success(resOTP.msg ? resOTP.msg : 'OTP has been sent to your email address');
        }
      },
      (err:any)=>{
        this.alertservice.error("Something went wrong, please try later");
      }
    )
    }else{
      this.resetPasswordForm.markAllAsTouched()
    }
    
  }

  onSubmitAdmission() {
    if (this.resetPasswordForm.valid) {
      this.accountService.forgetPassword(this.resetPasswordForm.value.emailorphonenumber).subscribe((resOTP:any)=>{
        if(resOTP.status){
          document.getElementById('resetPasswordID')?.click();
          document.getElementById('confirmPasswordID')?.click();
          this.showConfimPassword=false;
          this.alertservice.success(resOTP.msg ? resOTP.msg : 'OTP has been sent to your email address');
        }
      },
      (err:any)=>{
        this.alertservice.error("Something went wrong, please try later");
      }
    )
    } else {
      this.resetPasswordForm.markAllAsTouched()
    }
  }


  verifyOTP(){
   
    if(this.confirmPasswordForm.value.mobile != null && this.confirmPasswordForm.value.otp != null){
      this.accountService.forgetPasswordVerifyOTP(this.confirmPasswordForm.value.mobile,this.confirmPasswordForm.value.otp).subscribe((resVerify:any)=>{
        if(resVerify.status){
          this.showConfimPassword=true;
          this.alertservice.success(resVerify.msg ? resVerify.msg : 'OTP verified successfully. You can now reset your password');
        }else{
          this.showConfimPassword=false;
          this.alertservice.error(resVerify.msg ? resVerify.msg : 'Something went wrong');
        }
      },
      (err:any)=>{
        this.alertservice.error("Something went wrong, please try later");
      }
    )
    }
  }

  onConfirmSubmit() {
    if (this.confirmPasswordForm.valid) {
      this.accountService.forgetChangePassword(this.confirmPasswordForm.value.mobile,this.confirmPasswordForm.value.otp,this.confirmPasswordForm.value.confirmpassword).subscribe((resReset:any)=>{
        if(resReset.status){
          this.alertservice.success(resReset.msg ? resReset.msg : 'OTP reset successfully. You can now login using new password');
          document.getElementById('confirmPasswordID')?.click();
          this.alertservice.success('Password has been reset successfully');
          this.confirmPasswordForm.reset()
          this.resetPasswordForm.reset()
        }else{
          this.alertservice.error(resReset.msg ? resReset.msg : 'Something went wrong');
        }
      })
    } else {
      this.confirmPasswordForm.markAllAsTouched()
    }
  }

  findToaster() {
    // this.alertservice.warning("Get data")
  }

  showCustomalertservice() {
    // this.alertservice.show('Get data', '', {
    //     toastClass: 'custom-alertservice',
    //    // Add any specific icon classes you need
    //     closeButton: true,    // Enable close button
    // });
  }



  submitBookAppointmentForm(){
    console.log("get data",this.bookAppointmentForm);
    
    if(this.bookAppointmentForm.valid){
      this.bookAppointmentLastForm={...this.bookAppointmentForm.value}
      document.getElementById("scheduleAppointmentClose")?.click()
      document.getElementById("bookAppointmentDateTime")?.click()
      
    }else{
      this.bookAppointmentForm.markAllAsTouched()
    }
  }

  resetBookAppointment(){
    this.bookAppointmentForm.reset()
  }


  submitBookAppointmentDateTimeForm(){
    if(this.bookAppointmentDateTimeForm.valid){
      this.bookAppointmentLastForm = {
        ...this.bookAppointmentLastForm,
        ...this.bookAppointmentDateTimeForm.value 
      };
      console.log("get laste values",this.bookAppointmentLastForm);
      document.getElementById('bookAppintmentDateTimeClose')?.click()
    }else{
      this.bookAppointmentDateTimeForm.markAllAsTouched()
    }
  }

  resetBookAppointmentDateTime(){
    this.bookAppointmentDateTimeForm.reset()
  }


}
