<!-- students-feedback.component.html -->
<div class="testimonial-section container-fluid">
  <div class="container mx-auto gap-reduce rts-section-gap ">
    <!-- Section Header -->
    <div class="text-center mb-5 ">
      <h2 class="text-3xl font-bold mb-2">Students Testimonial</h2>
      <p class="text-gray-600">Hear What Our Students Are Buzzing About!</p>
    </div>

    <!-- Testimonials Container -->
    <div *ngIf="chunkedTestimonials.length > 0" class="testimonials-container relative" (mouseenter)="pauseAutoSlide()" (mouseleave)="resumeAutoSlide()">
      <div class="testimonials-grid" [@slideAnimation]="currentSlide">
        <div class="row" *ngIf="chunkedTestimonials[currentSlide]">
          <div
            class="col-md-4 mb-4"
            *ngFor="let testimonial of chunkedTestimonials[currentSlide]; let j = index"
          >
            <div class="card h-100 shadow-sm p-5 card-backgrounds">
              <!-- Star Rating -->
              <ul class="stars list-unstyled">
                <div class="mb-2"> 
                  <ng-container [ngSwitch]="testimonial?.stars">
                    <li *ngSwitchCase="1">
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                    </li>


                    
                    <li *ngSwitchCase="2">
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                    </li>  
                    <li *ngSwitchCase="3">
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                    </li>
                    <li *ngSwitchCase="4">
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-regular fa-star"></i>
                    </li>
                    <li *ngSwitchCase="5">
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                      <i class="fa-solid fa-star" style="color: #553cdf;"></i>
                    </li>
                    <li *ngSwitchDefault>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                    </li>
                  </ng-container>
                
                </div>
              </ul>
        
              <!-- Quote Mark -->
              <div class="quote-mark">
                <i class="bi bi-quote"></i>
              </div>
        
              <!-- Testimonial Text -->
              <p class="card-text feedback-text">
                {{ review(testimonial.feedback, 100, currentSlide + '-' + j) }}
                <button
                  *ngIf="testimonial.feedback.length > 100"
                  class="btn btn-link p-0 read-more"
                  (click)="toggleShowMore(currentSlide + '-' + j)"
                >
                  {{ showMoreStates[currentSlide + '-' + j] ? 'Read Less' : 'Read More' }}
                </button>
              </p>
        
              <!-- Author Info -->
              <div class="d-flex align-items-center mt-3">
                <div class="me-3">
                  <img
                    src="assets/images/about/01.jpg"
                    alt="avatar"
                    class="rounded-circle"
                    width="50"
                    height="50"
                  />
                </div>
                <div>
                  <h6 class="mb-0 author-name">{{ testimonial.name }}</h6>
                  <!-- <small class="text-muted author-work">{{ testimonial.work }}</small> -->
                  <small class="text-muted author-work">Student</small>
                </div>
                <img
                    src="assets/images/quotes.png"
                    alt="avatar"
                    class="quotes-icon"/>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div class="pagination-wrapper">
        <!-- Left Arrow -->
        <button 
          class="nav-button prev" 
          (click)="previousSlide()" 
          *ngIf="currentSlide > 0"
        >
          <i class="fa-solid fa-chevron-left" style="color: #553cdf;"></i>
        </button>
      
        <!-- Pagination Dots -->
        <div class="pagination-dots">
          <button 
            *ngFor="let dot of getDotArray()"
            class="dot"
            [class.active]="currentSlide === dot"
            (click)="currentSlide = dot"
          ></button>
        </div>
      
        <!-- Right Arrow -->
        <button 
          class="nav-button next" 
          (click)="nextSlide()" 
          *ngIf="currentSlide < chunkedTestimonials.length - 1"
        >
          <i class="fa-solid fa-chevron-right" style="color: #553cdf;"></i>
        </button>
      </div>
      
    </div>
    <div *ngIf="chunkedTestimonials.length == 0">
      <p>Students Testimonial Not Found</p>
    </div>
  </div>
</div>