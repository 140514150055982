import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { cart } from 'src/app/shared/modals/cart';
import { IndexService } from 'src/app/shared/services/index.service';
import { environment } from 'src/environments/environment';
import { DatePipe, formatDate, } from '@angular/common';
import { AccountService } from 'src/app/shared/services/account.service';
import { CourseService } from 'src/app/courses/services/course.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: ['./view-cart.component.scss']
})

export class ViewCartComponent implements OnInit {
  cartCourse!: cart[];
  apiURL = environment.url
  count!: number
  current_Date!: any;
  course_Date!: any;
  Days!: any;
  Math: any;
  logged_user = localStorage.getItem('AccessToken');
  Id = this.cryptojsService.decryptLocalUserId()
  cartTotal!: cart;
  wishlist!: cart[];
  storeAmt: any;
  cartCousesArray: any = []
  cartTotalAmt: number = 0;
  cartSubtotalAmt: number = 0;


  constructor(public router: Router, private toastr: ToastrService,private alertservice: AlertService,
    private indexService: IndexService, private course: CourseService, private accountservice: AccountService, public datepipe: DatePipe, private ActivateRoute: ActivatedRoute,
    private cryptojsService: CryptojsService,
    private cartService: CartService
  ) {

  }




  ngOnInit(): void {
    this.cartCourses();
  }


  cartCourses() {
    this.cartService.getCartCourse().subscribe((cart: any) => {
      if (cart.length > 0) {
        this.cartCousesArray = cart
        this.cartSubtotalAmt = this.cartCousesArray.reduce((acc: any, curr: any) => { return acc + (curr.offer_Price * curr.quantity) }, 0)
      } else {
        this.cartCousesArray = []
        this.cartTotalAmt = 0;
        this.cartSubtotalAmt = 0;
      }
    });
  }



  decreaseQuantity(course: any) {
    this.cartService.descreaseQuantity(course)
  }

  increaseQuantity(course: any) {
    this.cartService.increaseQuantity(course)
  }

  removeCourse(course: any) {
    this.cartService.removeCourse(course)
  }

  moveToWishlist(course_id: any) {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let Flag = 1;

    this.indexService.moveToWishlist(course_id, client_Id, Flag)
      .subscribe(results => {
        this.alertservice.success("Course moved successfully to Wishlist");
        this.myWishlist();
        this.cartCourses();

      });
  }

  moveToCart(course_id: any) {

    let client_Id = this.cryptojsService.decryptLocalUserId()
    let Flag = 2;

    this.indexService.moveToCart(course_id, client_Id, Flag)
      .subscribe(results => {
        console.log(results, 'moveToCart')
        this.alertservice.success("Course moved successfully to Cart");
        this.myWishlist();
        this.cartCourses();
      });
  }

  removeFromCart(course_id: any) {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let Flag = 0;
    this.indexService.removeFromCart(course_id, client_Id, Flag)
      .subscribe(results => {
        this.alertservice.success("Course removed successfully from Cart")
        this.cartCourses();
      });
  }

  removeToWishlist(course_id: any) {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let Flag = 3;
    this.indexService.removeFromCart(course_id, client_Id, Flag)
      .subscribe(results => {
        this.alertservice.success("Course removed successfully from Wishlist");
        this.myWishlist();
      });
  }

  myWishlist() {
    this.indexService.myWishlist(this.Id).subscribe(res => {
      this.wishlist = res;
    })
  }

  storeAmount(Price: any) {
    this.storeAmt = localStorage.setItem("shoppingCartStorage:", Price.textContent)
  }

  placeOrderPage() {
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      if (status) {
        this.router.navigateByUrl('/checkout')
      } else {
        this.router.navigateByUrl('/login')
        this.alertservice.warning('Please login first to place order')
      }
    })
  }

  onActivate() {
    this.indexService.onActivate()
  }
}

