<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div class="breadcrumb-main-wrapper">
              <h1 class="title">Cart</h1>
              <div class="pagination-wrapper">
                <a href="index.html">Home</a>
                <i class="fa-regular fa-chevron-right"></i>
                <a class="active" href="Instructor-2.html">Cart</a>
              </div>
            </div>

          </div>
          <div class="col-6">
            <div class="breadcrumb-img-book">
              <img src="\assets\images\breadcrumb\book.png" alt="">
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<main class="gap-reduce gap-reduce-bottom ms-main">
  <div class="ms-page-content">
    <article id="post-283" class="post-283 page type-page status-publish hentry">
      <div class="ms-default-page container ">
        <div class="woocommerce row  ">
          <div class=" col-12 col-md-12 col-lg-8">
            <div  class="ms-woocommerce-cart-form-wrapper  ">
              <table 
              class="table-responsive shop_table table-responsive shop_table_responsive cart woocommerce-cart-form__contents ms-cart-collaterals cart-collaterals">
              <thead>
                <tr>
                  <th class="product-remove">Action</th>
                  <th class="product-thumbnail">Image</th>
                  <th class="product-name">Product</th>
                  <th class="product-price">Price</th>
                  <th class="product-quantity">Discount</th>
                  <th class="product-subtotal">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  class="woocommerce-cart-form__cart-item cart_item" 
                  *ngFor="let course of cartCousesArray">
                  <td class="product-remove">
                    <button 
                      (click)="removeCourse(course)" 
                      class="remove" 
                      aria-label="Remove this item">
                      <svg class="float-end" 
                        viewBox="0 0 200 200" 
                        width="18" 
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z">
                        </path>
                      </svg>
                    </button>
                  </td>
                  <td class="product-thumbnail">
                    <a href="product-details.html">
                      <img 
                        [src]="apiURL + '/' + course?.image" 
                        class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" 
                        alt="Product Image">
                    </a>
                  </td>
                  <td class="product-name" data-title="Product">
                    <p class="product-title">{{ course?.name }}</p>
                  </td>
                  <td class="product-price" data-title="Price">
                    <span class="woocommerce-Price-amount amount">
                      <bdi>
                        <span class="woocommerce-Price-currencySymbol">&#8377;</span>{{ course?.actual_Price }}
                      </bdi>
                    </span>
                  </td>
                  <td class="product-quantity">
                    <span class="woocommerce-Price-amount amount">
                      <bdi>
                        <span class="woocommerce-Price-currencySymbol">&#8377;</span>
                        {{ course?.actual_Price - course?.offer_Price }}
                      </bdi>
                    </span>
                  </td>
                  <td class="product-subtotal" data-title="Subtotal">
                    <span class="woocommerce-Price-amount amount">
                      <bdi>
                        <span class="woocommerce-Price-currencySymbol">&#8377;</span>{{ course?.offer_Price }}
                      </bdi>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            
              <div class="text-center cart-head" *ngIf="cartCousesArray.length == 0;">
                <img src="assets/images/cart/cartempty.png" alt="img" class="responsive-img">
              </div>
            </div>
          </div>

          <div class=" col-12 col-md-12 col-lg-4 ">
            <div class="ms-cart-collaterals cart-collaterals">
              <div class="ms-cart-totals cart_totals">
                <h3 class="animated fadeIn cart-total">Cart totals</h3>
                <table class="shop_table shop_table_responsive">
                  <tbody>
                    <tr class="cart-subtotal">
                      <th>Subtotal</th>
                      <td data-title="Subtotal">
                        <span class="woocommerce-Price-amount amount">
                          <bdi><span class="woocommerce-Price-currencySymbol">&#8377;</span>{{cartSubtotalAmt}}</bdi>
                        </span>
                      </td>
                    </tr>
                    <tr class="order-total">
                      <th>Total</th>
                      <td data-title="Total">
                        <strong><span class="woocommerce-Price-amount amount">
                            <bdi><span
                                class="woocommerce-Price-currencySymbol">&#8377;</span>{{cartSubtotalAmt}}</bdi></span>
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-grid gap-2">
                  <a (click)="placeOrderPage()" class="btn btn-primary rts-button btn-lg font-size-button"> Proceed to checkout</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</main>


<div class="cart-bar">
  <div class="cart-header">
    <h3 class="cart-heading">MY CART (3 ITEMS)</h3>
    <div class="close-cart"><i class="fal fa-times"></i></div>
  </div>
  <div class="product-area">
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/01.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Construct Map</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">3 ×</span>
            <span class="product-price">&#8377;198.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="3">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/02.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name"> Bridge product</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">2 ×</span>
            <span class="product-price">&#8377;88.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item last-child">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/03.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Labour helmet</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">1 ×</span>
            <span class="product-price">&#8377;289.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="cart-bottom-area">
    <span class="spend-shipping"><i class="fal fa-truck"></i> SPENT <span class="amount">&#8377;199.00</span> MORE
      FOR FREE SHIPPING</span>
    <span class="total-price">TOTAL: <span class="price">&#8377;556</span></span>
    <a href="checkout.html" class="checkout-btn cart-btn">PROCEED TO CHECKOUT</a>
    <a href="cart.html" class="view-btn cart-btn">VIEW CART</a>
  </div>
</div>


<div id="side-bar" class="side-bar header-two">
  <button class="close-icon-menu"><i class="far fa-times"></i></button>
  <div class="inner-main-wrapper-desk">
    <div class="thumbnail">
      <img src="assets/images/banner/04.jpg" alt="elevate">
    </div>
    <div class="inner-content">
      <h4 class="title">We Build Building and Great Constructive Homes.</h4>
      <p class="disc">
        We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new
        technologies.
      </p>
      <div class="footer">
        <h4 class="title">Got a project in mind?</h4>
        <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
      </div>
    </div>
  </div>

  <div class="mobile-menu-main">
    <nav class="nav-main mainmenu-nav mt--30">
      <ul class="mainmenu metismenu" id="mobile-menu-active">
        <li>
          <a href="index.html" class="main">Home</a>
        </li>
        <li>
          <a href="about.html" class="main">About Us</a>
        </li>
        <li>
          <a href="course-one.html" class="main">Course</a>

        </li>
        <li>
          <a href="contact.html" class="main">Contact Us</a>

        </li>
        <li>
          <a href="FAQ.html" class="main">FAQ</a>

        </li>
      </ul>
    </nav>

    <div class="buttons-area">
      <a href="log-in.html" class="rts-btn btn-border">Log In</a>
      <a href="registration.html" class="rts-btn btn-primary">Sign Up</a>
    </div>

    <div class="rts-social-style-one pl--20 mt--50">
      <ul>
        <li>
          <a href="#">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="search-input-area">
  <div class="container">
    <div class="search-input-inner">
      <div class="input-div">
        <input class="search-input autocomplete" type="text" placeholder="Search by keyword or #">
        <button><i class="far fa-search"></i></button>
      </div>
    </div>
  </div>
  <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>

<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>