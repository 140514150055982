import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from 'src/app/courses/services/course.service';
import { environment } from 'src/environments/environment';
import { IndexService } from '../../services/index.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../services/account.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  courses: any;
  courseDisplay: any;
  apiURL = environment.url;
  contactUsForm!: FormGroup;
  submitted = false;
  phoneNumber: string = '8828228330';  // Enter the phone number in international format
  defaultMessage: string = '“Hello, I would like to know more about the course.”';
  attributesAdded: boolean = false;
  userPresent: boolean = false;
  Id: any;
  subscription: any = [];
  admissionForm!: FormGroup;
Math: any;

  constructor(private indexService: IndexService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder,
    private toastr: ToastrService, private alertservice: AlertService, private accountservice: AccountService, private cryptojsService: CryptojsService
  ) { }

  ngOnInit(): void {
    this.createAdmissionForm()
    this.contactUsFormFunction()
    this.getAllCourses();
    this.Id = this.cryptojsService.decryptLocalUserId()
    this.mySubscription()
  }

  createAdmissionForm() {
    this.admissionForm = this.formBuilder.group({
      client_Id: ['', Validators.required],
      name: ['', Validators.required],
      fathersName: ['', Validators.required],
      surname: ['', Validators.required],
      birthDate: ['', Validators.required],
      guardianMobileNo: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      yearOfAppearing: ['', Validators.required],
      studentType: ['', Validators.required],
      howDidYouKnow: ['', Validators.required],
    })
  }

  onSubmitAdmission() {
    this.admissionForm.controls['client_Id'].setValue(this.Id)
    if (this.admissionForm.valid) {
      this.indexService.postAdmissionForm(this.admissionForm.value).subscribe((resSA: any) => {
        if (resSA) {
          this.admissionForm.reset()
          document.getElementById('admissionFormCloseId')?.click()
        }
      })
    } else {
      this.admissionForm.markAllAsTouched();
    }
  }

  contactUsFormFunction() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      phone_Number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      message: ['', Validators.required],
    });
  }

  getAllCourses() {
    this.courses = [];
    this.indexService.getCourse().subscribe(response => {
      this.courses = response['items'];
      if (this.courses.length > 0) {
        this.courseDisplay = this.courses[this.courses.length - 1]
        this.courseDisplay.star=Math.floor(this.courseDisplay.star) 
      }
    }, err => {
    })

  }

  displayStyle = "none";
  openDetails() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }

  onActivate() {
    this.indexService.onActivate()
  }


  contactUs() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched()
      return;
    }
    this.indexService.contactUs(this.contactUsForm.value)
      .subscribe(result => {
        this.alertservice.success("Form submitted Successfully.")
        this.contactUsForm.reset();
        this.submitted = false;
      });
  }

  getWhatsAppLink(): string {
    return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.defaultMessage)}`;
  }

  checkUserPresence(callback: (present: boolean) => void) {
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      this.userPresent = status;
      callback(this.userPresent);
    });
  }

  handleClick() {
    this.checkUserPresence((present) => {
      if (present) {
        this.attributesAdded = true;
      } else {
        this.alertservice.warning('Please login for applying admission form')
      }
    });
  }

  mySubscription() {
    this.indexService.admissionFormStatus(this.Id).subscribe(responseAFStatus => {
      if (responseAFStatus) {
        //user submitted the form
      } else {
        this.accountservice.mySubscription(this.Id).subscribe(response => {
          this.subscription = response
          if (this.subscription.length == 1) {
            document.getElementById("openAdmissionForm")?.click()
          }
        })
      }
    })
  }

setDefaultImage(event: Event): void {
  const imgElement = event.target as HTMLImageElement;
  imgElement.src = '../../../../assets/defaultimg/defaultMainImg.png';
}

}
