<div class="login-bg pt-5 pb-5">
  <div class="container ">
    <div class="row ">
      <div class="col-12 d-flex justify-content-center">
          <div class="login-page-form-area">
              <h4 class="title">Login to Your Account👋</h4>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="single-input-wrapper">
                    <label for="email">Your Email or Mobile Number</label>
                    <input id="email" type="email" placeholder="Enter Your Email"
                      formControlName="userNameOrEmailAddress">
                    <div class="text-danger"
                      *ngIf="loginForm.controls['userNameOrEmailAddress'].invalid && loginForm.controls['userNameOrEmailAddress'].touched">
                      Email or Number is required
                    </div>
                  </div>
                  <!-- <div class="single-input-wrapper">
              <label for="mob">Your Mobile Number</label>
              <input id="mob" type="number" placeholder="Enter Your Mobile Number">
            </div> -->

                  <div class="single-input-wrapper">
                    <label for="password">Your Password</label>
                    <div class="input-icon-wrapper">
                      <input id="password" [type]="showPassword ? 'text' : 'password'" placeholder="Password"
                        formControlName="password">
                      <i class="fa fa-look" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"
                        (click)="displayPassWord()"></i>
                    </div>
                    <div class="text-danger"
                      *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
                      Password is required
                    </div>
                  </div>

                  <div class="single-checkbox-filter">
                    <div class="check-box">
                      <input type="checkbox" id="type-1" (change)="rememberMeCall($event)"
                        [checked]="isRememberMeChecked">
                      <label for="type-1">Remember Me</label><br>
                    </div>
                  </div>
                  <button type="submit" class="rts-btn btn-primary">Login</button>
                  <p>If you forgot your password, <a class="c-pointer" data-bs-toggle="modal"
                      data-bs-target="#resetPasswordModal">you can reset it here.</a></p>
                  <div class="google-apple-wrapper">
                    <div id="g_id_onload"
                      data-client_id="1035941459256-9t7fmuelviq37bd28vu64e8i7afq03iu.apps.googleusercontent.com"
                      data-context="signin" data-ux_mode="popup"
                      data-login_uri="https://ohmadmin.activeneuronstech.com/api/app/my-account/my-register"
                      data-auto_prompt="false" data-callback="onGoogleSignIn">
                  
                    </div>

                    <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline"
                      data-text="signin_with" data-size="large" data-logo_alignment="left">
                    </div>

                    <div class="google">
                      <img src="assets/images/contact/07.png" alt="contact" (click)="signInWithApple()">
                    </div>
                  </div>
                  <p>Don't Have an account? <a routerLink="/register">Registration</a></p>
              </form>
          </div>
      </div>
    </div>
  </div>
  <div class="bubbles_wrap">
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
    <div class="bubble x5"></div>
    <div class="bubble x6"></div>
    <div class="bubble x7"></div>
    <div class="bubble x8"></div>
    <div class="bubble x9"></div>
    <div class="bubble x10"></div>
  </div>

  <!-- Scroll To Top Start -->
  <a href="#main-banner" class="scroll-top" id="scroll-to-top">
    <i class="fa fa-arrow-up" aria-hidden="true"></i>
  </a>



  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#appleIdExtraData"
    id="appleIdExtra" style="display: none;">
    Apple Form Lounch
  </button>
  <div class="modal fade" id="appleIdExtraData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Apple Form</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="appleIdExtraClose"
            (click)="resetAppleForm()"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="appleForm" (ngSubmit)="onAppleFormSubmit()" class="needs-validation" novalidate>
            <div class="mb-3">
              <label for="name" class="form-label">Name</label>
              <input type="text" id="name" class="form-control" formControlName="name" placeholder="Enter your name"
                required />
              <div *ngIf="appleForm.controls['name'].invalid && appleForm.controls['name'].touched" class="text-danger">
                Name is required.
              </div>
            </div>

            <div class="mb-3">
              <label for="surname" class="form-label">Surname</label>
              <input type="text" id="surname" class="form-control" formControlName="surname"
                placeholder="Enter your surname" required />
              <div *ngIf="appleForm.controls['surname'].invalid && appleForm.controls['surname'].touched"
                class="text-danger">
                Surname is required.
              </div>
            </div>

            <div class="mb-3">
              <label for="number" class="form-label">Mobile Number</label>
              <input type="text" id="number" class="form-control" formControlName="number"
                placeholder="Enter your mobile number" maxlength="10" minlength="10" required />
              <div *ngIf="appleForm.controls['number'].invalid && appleForm.controls['number'].touched"
                class="text-danger">
                A valid mobile number is required.
              </div>
            </div>

            <div class="mb-3">
              <label for="password" class="form-label">Password </label>
              <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" id="password" class="form-control"
                  formControlName="password" placeholder="Enter your password" required />
                <span class="input-group-text" (click)="displayPassWord()" style="cursor: pointer;">
                  <i [class]="showPassword ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'"></i>
                </span>
                <div *ngIf="appleForm.controls['password'].invalid && appleForm.controls['password'].touched"
                  class="text-danger">
                  Password is required and must be at least 6 characters.
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="appleForm.invalid">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div> 
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#googleIdExtraData"
    id="googleIdExtra" style="display: none;">
    Google Form Lounch
  </button>
  <div class="modal fade" id="googleIdExtraData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Please fill the information</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="googleIdExtraClose"
            (click)="resetGoogleForm()"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="googleForm" (ngSubmit)="onGoogleFormSubmit()" class="needs-validation" novalidate>
            <div class="mb-3">
              <label for="number" class="form-label ng-untouched ng-pristine ng-invalid  ">Mobile Number</label>
              <input type="text" id="number" class="form-control ng-untouched ng-pristine ng-invalid " formControlName="number"
                placeholder="Enter your mobile number" maxlength="10" minlength="10" required />
              <div *ngIf="googleForm.controls['number'].invalid && googleForm.controls['number'].touched"
                class="text-danger">
                A valid mobile number is required.
              </div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password </label>
              <div class="input-group">
                <div class="input-group">
                  <input 
                    [type]="showPassword ? 'text' : 'password'" 
                    id="password" 
                    class="form-control" 
                    formControlName="password" 
                    placeholder="Enter your password" 
                    maxlength="10" 
                    minlength="10" 
                    required 
                  />
                  <span 
                    class="input-group-text" 
                    (click)="displayPassWord()" 
                    style="cursor: pointer;"
                  >
                    <i [class]="showPassword ? 'fa fa-fw fa-eye-slash' : 'fa fa-fw fa-eye'"></i>
                  </span>
                </div>
                
                <div *ngIf="googleForm.controls['password'].invalid && googleForm.controls['password'].touched"
                  class="text-danger">
                  A valid password is required.
                </div>
              </div>

            </div>
            <button type="submit" class="rts-btn btn-primary float-end" [disabled]="googleForm.invalid">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
